import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3499616894/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "namespacebuilder"
    }}>{`namespaceBuilder`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`namespaceBuilder`}</inlineCode>{` function is used to create namespaced Redux action types. It returns a function that can be called
a variety of actions in a namespace:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

const ns = Utils.namespaceBuilder('app-area');
ns('SET_DATA'); // returns the string: '@kd/app-area/SET_DATA'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      